// libs
import KisTabs from 'kis-tabs';
import Swiper from 'swiper';
// modules
import DynamicGradient from '../../../modules/dynamic-gradient/dynamic-gradient';

const tabs = new KisTabs({
	tabSelector: '.tab',
	btnSelector: '.tabs__nav-btn',
});

tabs.init();

const foodStars = new DynamicGradient({
	selector: '.tab-card__stars',
	fullColor: 'hsl(6, 100%, 62%, 1)',
	emptyColor: 'hsl(6, 100%, 62%, 0.5)',
});

foodStars.init();

const tabsSwiper = new Swiper('.tabs__nav', {
	slideClass: 'tabs__nav-btn',
	slidesPerView: 'auto',
	setWrapperSize: true,
	freeMode: {
		enabled: true,
		sticky: false,
	},
});
