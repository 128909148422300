// libs
import Swiper, { Navigation, Pagination } from 'swiper';

import { breakpoints } from '../../../assets/styles/variables';

const { sm } = { ...breakpoints }; // lg, md, sm, xs

Swiper.use([Navigation, Pagination]);

const articlesSlider = new Swiper('.articles-slider', {
	slideClass: 'articles-slide',
	slidesPerView: 1,
	spaceBetween: 20,
	navigation: {
		nextEl: '.articles-slider__nav-next',
		prevEl: '.articles-slider__nav-prev',
	},
	breakpoints: {
		[sm]: {
			slidesPerView: 'auto',
		},
	},
	pagination: {
		el: '.articles-slider__pagination',
		clickable: true,
	},
});
