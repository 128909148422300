export default class DynamicGradient {
	constructor({
		selector,
		fullColor = 'black',
		emptyColor = 'white',
	}) {
		this.selector = selector;
		this.fullColor = fullColor;
		this.emptyColor = emptyColor;
	}

	init = () => {
		const stars = Array.from(document.querySelectorAll(this.selector));
		stars.forEach((item) => {
			const value = +item.getAttribute('value');
			if (value) {
				const percent = value * 20;
				item.style.background = 'linear-gradient(90deg, '
						+ `${this.fullColor} 0%, `
						+ `${this.fullColor} ${percent}%, `
						+ `${this.emptyColor} ${percent}%, `
						+ `${this.emptyColor} 100%)`;
			}
		});
	};
}
