import DynamicGradient from '../../../modules/dynamic-gradient/dynamic-gradient';

const experienceStars = new DynamicGradient({
	selector: '.experience__stars-icon',
	fullColor: '#FFB800',
	emptyColor: 'rgba(255, 255, 255, 0.2)',
});

experienceStars.init();

