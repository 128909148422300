// styles
import './home.scss';
import 'swiper/swiper-bundle.css';

// for all page
import '../../components/app/header/header';
import '../../components/app/footer/footer';
import '../../components/app/menu/menu';
import '../../components/app/mobile-menu/mobile-menu';
import '../../components/sections/member-section/member-section';
// only for index.html
import '../../components/app/awards/awards';
import '../../components/sections/thrive/thrive';
import '../../components/sections/calculator/calculator-1';
import '../../components/sections/calculator/calculator-2';
import '../../components/sections/experience/experience';
import '../../components/sections/faq-main-page/faq-main-page';
import '../../components/sections/tabs/tabs';
import '../../components/sections/articles/articles';
import '../../components/sliders/reviews-slider/reviews-slider';
// import '../../components/sections/buyside/buyside';
