import Swiper, { Navigation, Pagination } from 'swiper';
import { breakpoints } from '../../../assets/styles/variables';

const {
	lg, md, sm,
} = { ...breakpoints }; // lg, md, sm, xs,

Swiper.use([Navigation, Pagination]);

const thriveSlider = new Swiper('.thrive-slider', {
	spaceBetween: 20,
	slideClass: 'thrive-slider__slide',
	navigation: {
		nextEl: '.thrive-slider__button-next',
		prevEl: '.thrive-slider__button-prev',
	},
	pagination: {
		el: '.thrive-slider__pagination',
		clickable: true,
	},
	breakpoints: {
		// when window width is >= 0
		0: {
			slidesPerView: 1,
		},
		// when window width is >= 570px
		[sm]: {
			slidesPerView: 2,
		},
		// when window width is >= 811px
		[md]: {
			slidesPerView: 3,
		},
		// when window width is >= 1024px
		[lg]: {
			slidesPerView: 4,
		},
	},
});

