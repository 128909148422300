// libs
import Swiper, { Navigation, Pagination } from 'swiper';
import DynamicGradient from '../../../modules/dynamic-gradient/dynamic-gradient';
import { breakpoints } from '../../../assets/styles/variables';

const { md } = { ...breakpoints }; // lg, md, sm, xs

Swiper.use([Navigation, Pagination]);

const reviewsSlider = new Swiper('.reviews-slider__wrapper', {
	spaceBetween: 20,
	slideClass: 'reviews-slide',

	navigation: {
		nextEl: '.reviews-slider__button-next',
		prevEl: '.reviews-slider__button-prev',
	},
	pagination: {
		el: '.reviews-slider__pagination',
		clickable: true,
	},
	breakpoints: {
		0: {
			slidesPerView: 'auto',
		},
		[md]: {
			slidesPerView: 2,
		},
	},
});

const reviewStars = new DynamicGradient({
	selector: '.reviews__stars-icon',
	fullColor: '#ffb700',
	emptyColor: '#eeeeee',
});

reviewStars.init();

