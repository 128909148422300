// styles
import './awards.scss';
// libs
import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

const awardsSlider = new Swiper('.awards__slider', {
	slideClass: 'awards__slider-card',
	slidesPerView: 2,
	spaceBetween: 10,
	navigation: {
		nextEl: '.awards__slider-button--next',
		prevEl: '.awards__slider-button--prev',
	},
});
